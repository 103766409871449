// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/neil/Code/core-gui/ui/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-debug-js": () => import("/Users/neil/Code/core-gui/ui/src/pages/debug.js" /* webpackChunkName: "component---src-pages-debug-js" */),
  "component---src-pages-docs-js": () => import("/Users/neil/Code/core-gui/ui/src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-index-js": () => import("/Users/neil/Code/core-gui/ui/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-rooms-index-js": () => import("/Users/neil/Code/core-gui/ui/src/pages/rooms/index.js" /* webpackChunkName: "component---src-pages-rooms-index-js" */),
  "component---src-pages-sensors-add-js": () => import("/Users/neil/Code/core-gui/ui/src/pages/sensors/add.js" /* webpackChunkName: "component---src-pages-sensors-add-js" */),
  "component---src-pages-settings-advanced-jsx": () => import("/Users/neil/Code/core-gui/ui/src/pages/settings/advanced.jsx" /* webpackChunkName: "component---src-pages-settings-advanced-jsx" */),
  "component---src-pages-settings-index-js": () => import("/Users/neil/Code/core-gui/ui/src/pages/settings/index.js" /* webpackChunkName: "component---src-pages-settings-index-js" */),
  "component---src-pages-settings-integrations-homekit-js": () => import("/Users/neil/Code/core-gui/ui/src/pages/settings/integrations/homekit.js" /* webpackChunkName: "component---src-pages-settings-integrations-homekit-js" */),
  "component---src-pages-settings-integrations-hue-js": () => import("/Users/neil/Code/core-gui/ui/src/pages/settings/integrations/hue.js" /* webpackChunkName: "component---src-pages-settings-integrations-hue-js" */),
  "component---src-pages-settings-integrations-ifttt-js": () => import("/Users/neil/Code/core-gui/ui/src/pages/settings/integrations/ifttt.js" /* webpackChunkName: "component---src-pages-settings-integrations-ifttt-js" */),
  "component---src-pages-settings-room-js": () => import("/Users/neil/Code/core-gui/ui/src/pages/settings/room.js" /* webpackChunkName: "component---src-pages-settings-room-js" */),
  "component---src-pages-settings-rooms-js": () => import("/Users/neil/Code/core-gui/ui/src/pages/settings/rooms.js" /* webpackChunkName: "component---src-pages-settings-rooms-js" */)
}

